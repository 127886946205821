import React, { useEffect, useState } from "react";
import "./creditDebit.css";
import { useDispatch, useSelector } from "react-redux";

import Loaders from "../Loader/Loader";
import DebitTabActiveOpen from "../Dashboard/debitTabOpen";
import CreditTabActiveOpen from "../Dashboard/creditTabOpen";

function CreditDebitTab() {
  const loadingState = useSelector((state) => state.fileUpload.loadingState);

  const [callTab, setCallTab] = useState("CreditTabActive");
  const goTocreditAndDebit = (value) => {
    console.log("goTocreditAndDebit", value);
    setCallTab(value);
  };

  const creditDebitTabActive = () => {
    switch (callTab) {
      case "DebitTabActive":
        return <DebitTabActiveOpen />;
      default:
        return <CreditTabActiveOpen />;
    }
  };

  return (
    <>
      {loadingState ? <Loaders /> : ""}
      <div className="hide-left"></div>
      <div className="creditDebitTabActive-css">
        <div className="credit">
          <div className="vendor-credit">
            <div className="credit-parent">
              <div
                className={
                  callTab === "DebitTabActive" ? "tab-oppDis" : "tab-oppEna"
                }
              >
                <div
                  className="creditTab"
                  onClick={() => goTocreditAndDebit("CreditTabActive")}
                >
                  <span class="title">View Credit</span>
                  <span class="curve"></span>
                </div>
              </div>
              <div
                className={
                  callTab === "CreditTabActive" ? "tab-oppDis" : "tab-oppEna"
                }
              >
                <div
                  className="debitTab"
                  onClick={() => goTocreditAndDebit("DebitTabActive")}
                >
                  <span class="title">View Debit</span>
                  <span class="curve"></span>
                </div>
              </div>
            </div>
          </div>
          {creditDebitTabActive()}
        </div>
      </div>
    </>
  );
}

export default CreditDebitTab;
