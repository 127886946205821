import React, { useState } from "react";
import "./masterUpload.css";
import Listbuttons from "../Buttons/buttons";
import DragDropFile from "../Fileupload/fileupload";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { dealsMasterUpload } from "../../redux/actions/masterUpload";
import { marginMasterUpload } from "../../redux/actions/masterUpload";

function MasterUpload() {
  let dispatch = useDispatch();

  let [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(0);
  const [uploadFileCall, setUploadFile] = useState("");
  const getFile = useSelector((state) => state.fileUpload.getFile);
  const failUpload = useSelector((state) => state.fileUpload.failUpload);

  const [masterDropdown, setmasterDropdown] = useState({});
  // console.log("masterDropdown", masterDropdown);

  const dropdownMaster = (e) => {
    setmasterDropdown(e.target.value);
  };

  // console.log("getFile", getFile);

  const aftermasterUpload = async () => {
    if (masterDropdown === "marginMaster") {
      // console.log("marginMasterUpload");
      setSubmit(true);
      setProcess(0);
      // console.log(getFile);
      dispatch(marginMasterUpload(getFile));
    } else {
      // console.log("dealsMasterUpload");
      setSubmit(true);
      setProcess(0);
      // console.log(getFile);
      dispatch(dealsMasterUpload(getFile));
    }
  };

  return (
    <>
      <div className="hide-left"></div>
      <div className="history-head">Master Upload</div>
      <br></br>

      <form>
        <select className="master-dropdown" onChange={dropdownMaster}>
          <option value="dealMaster">Deals Master</option>
          <option value="marginMaster">Margin Master</option>
        </select>
      </form>
      <div className="datePick">
        <>
          <DragDropFile
            uploadFileCall={uploadFileCall}
            setUploadFile={setUploadFile}
          />
        </>
      </div>

      <div className="buttonAlign">
        <Listbuttons
          disabled={uploadFileCall === "" ? true : false}
          data={"Submit"}
          className="data-button"
          onClick={aftermasterUpload}
        />
      </div>
    </>
  );
}

export default MasterUpload;
