import React from "react";
import MonthPicker from "month-year-picker";
import "./datepicker.css";

function DatePickers({ selectedDate }) {
  return (
    <div>
      <MonthPicker
        name="MonthYearPickers"
        Id={selectedDate}
        allowedYears={{ after: new Date().getFullYear() - 2 }}
        ChangeYearFormat="true"
      />
    </div>
  );
}

export default DatePickers;
