// import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toast(message, type) {
  // console.log(type, message, "message");
  const notify = () =>
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return notify();
}
export default Toast;
