import * as types from "../actionTypes";

const initialState = {
  process: [],
  dropDownfail: false,
  loading: false,
  tabEnable: false,
};

export const dataProcessingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COLUMN_MAPPING:
      // console.log("UPLOAD_DATA_PROCESSING", action.payload);
      return {
        ...state,
        process: action.payload,
        loading: false,
      };
    case types.SELECTED_DROPDOWN_RESPONSE:
      // console.log("SELECTED_DROPDOWN_RESPONSE", action.payload);
      return {
        ...state,
        dropDownfail: action.payload,
        loading: false,
      };

    case types.TAB_ENABLE:
      // console.log("TAB_ENABLE", action.payload);
      return {
        ...state,
        tabEnable: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
