import React from "react";
import DatePickers from "../Datepicker/datepicker";
import "./History.css";
import { sendYearAndMonthForHistory } from "../../redux/actions/history";
import { useDispatch, useSelector } from "react-redux";
import Loaders from "../Loader/Loader";
import HistoryTable from "./HistoryTable";

const History = () => {
  let dispatch = useDispatch();

  const getApiResponceForHistory = useSelector(
    (state) => state.historyGetValue.getHistoryResponce.data
  );

  console.log("getApiResponceForHistory", getApiResponceForHistory);

  const getData = async () => {
    const selectedDate = document.getElementById("MonthYearHistory")?.value;
    // console.log("selectedDate", selectedDate);
    var year = selectedDate.slice(3);
    if (year === "") {
      year = null;
    } else {
      year = selectedDate.slice(3);
    }
    // console.log("year", year);
    var month = selectedDate.substring(0, 2);
    // console.log("month", month);
    if (month === "") {
      month = null;
    } else {
      month = selectedDate.substring(0, 2);
    }
    // console.log(year, month);
    dispatch(sendYearAndMonthForHistory(year, month));
  };

  const loadingState = useSelector((state) => state.fileUpload.loadingState);

  return (
    <>
      {loadingState ? <Loaders /> : ""}
      <div className="hide-left"></div>
      <div className="history-head">History</div>
      <div className="hide-left"></div>
      <div className="history-button">
        <DatePickers selectedDate="MonthYearHistory" />{" "}
        <div>
          <button className="data-button btn-pad" onClick={getData}>
            Fetch data
          </button>
        </div>
      </div>
      {!getApiResponceForHistory ? (
        <>
          <div className="pleaseSelect">
            <h3>Please select date and month</h3>
          </div>
        </>
      ) : (
        <HistoryTable />
      )}
    </>
  );
};
export default History;
