import React, { useEffect, useState } from "react";
import "./Scheme.css";
import "../CreditTab/Credit.css";
import Amount from "../../assets/images/TotalAmount.png";
import Sales from "../../assets/images/TotalSales.png";
import System from "../../assets/images/TotalSystem.png";
import download from "../../assets/images/report.png";
import { useDispatch, useSelector } from "react-redux";
import document from "../../assets/images/success.png";
import { getSchemeDataValue } from "../../redux/actions/scheme";
import Loaders from "../Loader/Loader";

function Scheme() {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchemeDataValue());
  }, []);

  const getFileId = useSelector((state) => state.fileUpload.getFileId);

  const getSchemeValueFromApi = useSelector(
    (state) => state.schemeApiValue.getSchemeData
  );

  console.log("getFileId", getFileId);

  const loadingState = useSelector((state) => state.fileUpload.loadingState);

  const fileDownloadSuccess =
    "https://arvs-api.theretailinsights.co/api/v1/reports/scheme_success/";

  const fileDownloadError =
    "https://arvs-api.theretailinsights.co/api/v1/reports/scheme_error/";

  const forSuccess = fileDownloadSuccess + getFileId;

  const forError = fileDownloadError + getFileId;

 
  return (
    <>
      {loadingState ? <Loaders /> : ""}
      <div className="nav-tab-disable-for-hide"></div>
      <div className="nav-tab-disable-for-hide2"></div>
      <div>
        <div className="container">
          <div className="scheme-data">
            <img src={Sales} alt="Sales" />
            <p>Total Sales Value</p>
            <p>
              <b>Rs. {getSchemeValueFromApi.total_sales}</b>
            </p>
          </div>
          <div className="scheme-data">
            <img src={System} alt="System" />
            <p>Total System Sales Value</p>
            <p>
              <b>Rs. {getSchemeValueFromApi.total_gsv}</b>
            </p>
          </div>
          <div className="scheme-data">
            <img src={Amount} alt="Amount" />
            <p>Total Amount Of Discrepancy</p>
            <p>
              <b>Rs. {getSchemeValueFromApi.error_total}</b>
            </p>
          </div>
        </div>
        <div className="contain">
          <div className="card">
            <img className="schemeDownloadImage" src={download} alt="Sales" />
            <p>Download the Discrepancy Report !</p>

            <button class="btn">
              <a href={forError} download>
                <p>Download</p>
              </a>
            </button>
          </div>
          <div className="card">
            <img className="schemeDownloadImage" src={document} alt="System" />
            <p>Download the Success Report !</p>
            <button class="btn">
              <a href={forSuccess} download>
                <p>Download</p>
              </a>
            </button>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default Scheme;
