import * as types from "../actionTypes";

const initialState = {
  mapping: {},
  loading: false,
  dropDownNotSelect: false,
  loadingState: false,
};

export const columnMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COLUMN_MAPPING:
      // console.log("GET_COLUMN_MAPPING", action.payload);
      return {
        ...state,
        mapping: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export const mappedValueToSend = (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_DATA_PROCESSING:
      //   console.log("GET_COLUMN_MAPPING", action.payload);
      return {
        ...state,
        mapping: action.payload,
        loading: false,
      };
    case types.SELECTED_DROPDOWN_RESPONSE:
      return {
        ...state,
        dropDownNotSelect: action.payload,
        loading: false,
      };
    case types.LOADERS_CALL:
      return {
        ...state,
        // getFile: action.payload,
        loadingState: action.payload,
      };

    default:
      return state;
  }
};
