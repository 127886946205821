import { combineReducers } from "redux";
import { uploadFileReducer } from "./reducers/uploadFile";
import { columnMappingReducer } from "./reducers/columnMapping";
import { dataProcessingReducer } from "./reducers/dataProcessing";
import { downloadFileReducer } from "./reducers/downloadFile";
import { mappedValueToSend } from "./reducers/columnMapping";
import { historyReducer } from "./reducers/history";
import { dashboardApi } from "./reducers/dashboard";
import { schemeApi } from "./reducers/scheme";

const rootReducer = combineReducers({
  fileUpload: uploadFileReducer,
  mappingColumn: columnMappingReducer,
  processData: dataProcessingReducer,
  downloadFile: downloadFileReducer,
  dropDownValue: mappedValueToSend,
  historyGetValue: historyReducer,
  dashboardApiValue: dashboardApi,
  schemeApiValue: schemeApi,
});

export default rootReducer;
