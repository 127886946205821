import React, { useState } from "react";
import "./dashboard.css";
import Loaders from "../Loader/Loader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import search from "../../assets/images/search.png";
import DownloadImgImage from "../../assets/images/download-rport.png";
import DatePickers from "../Datepicker/datepicker";

function CreditTabActiveOpen() {
  const loadingState = useSelector((state) => state.fileUpload.loadingState);
  const getDebitTabValue = async () => {
    const selectedDate = document.getElementById("MonthYearCredit")?.value;
    console.log("expenseReportDownload", selectedDate);
  };
  return (
    <>
      {loadingState ? <Loaders /> : ""}
      <div className="vendorTab">
        <div className="searchbard-month">
          {/* <div className="search">
            <form action="/action_page.php">
              <label for="fname">
                <img
                  className="search-img"
                  src={search}
                  alt="chart-img"
                  width="16px"
                  height="auto"
                />
              </label>
              <input
                className="search-field"
                type="text"
                id="fname"
                name="fname"
              ></input>
            </form>
          </div> */}
          <div className="month">
            <div className="buttons-get-month-credit">
              <div className="buttons-get-month">
                <DatePickers selectedDate="MonthYearCredit" />
                <div className="selectDate" onClick={getDebitTabValue}>
                  Get report
                </div>
              </div>
            </div>
          </div>
        </div>
        <table>
          <tr>
            <th>Region </th>
            <th>Dealer Name</th>
            <th>DMS Code </th>
            <th>Channel</th>
            <th>Brand</th>
            <th>Total Sales Qty</th>
            <th>Total MRP </th>
            <th>Total GSV </th>
            <th>Total Discount </th>
            <th>Type </th>
          </tr>
          <tr>
            <td>CreditTab</td>
            <td>Lifestyle</td>
            <td>Apr 10, 2020</td>
            <td>Rs. 3,400</td>
            <td>Germany</td>
            <td>LF - 0012</td>
            <td>Lifestyle</td>
            <td>Apr 10, 2020</td>
            <td>Rs. 3,400</td>
            <td>Germany</td>
          </tr>
          <tr>
            <td>LF - 0012</td>
            <td>Lifestyle</td>
            <td>Apr 10, 2020</td>
            <td>Rs. 3,400</td>
            <td>LF - 0012</td>
            <td>Lifestyle</td>
            <td>Apr 10, 2020</td>
            <td>Rs. 3,400</td>
            <td>Germany</td>
            <td>Germany</td>
          </tr>
          <tr>
            <td>LF - 0012</td>
            <td>LF - 0012</td>
            <td>Lifestyle</td>
            <td>Apr 10, 2020</td>
            <td>Rs. 3,400</td>
            <td>Germany</td>
            <td>Lifestyle</td>
            <td>Apr 10, 2020</td>
            <td>MRs. 3,400</td>
            <td>Germany</td>
          </tr>
        </table>
      </div>
    </>
  );
}

export default CreditTabActiveOpen;
