import React, { useState } from "react";
import "./DocumentUpload.css";
import DatePickers from "../Datepicker/datepicker";
import ProgressBars from "../StatusBar/statusbar";
import Listbuttons from "../Buttons/buttons";
import DragDropFile from "../Fileupload/fileupload";
import ColumnMapping from "./columnMapping";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { addFileUpload } from "../../redux/actions/uploadFile";

function UploadDocument() {
  let dispatch = useDispatch();

  let [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(0);
  const [uploadFileCall, setUploadFile] = useState("");
  const getFile = useSelector((state) => state.fileUpload.getFile);
  const failUpload = useSelector((state) => state.fileUpload.failUpload);

  // console.log("getFile", getFile);

  const afterUpload = async () => {
    const selectedDate = document.getElementById("MonthYearUpload")?.value;

    setSubmit(true);
    setProcess(0);
    console.log("Date", selectedDate);
    var year = selectedDate.slice(3);
    // console.log("year", year);
    var month = selectedDate.substring(0, 2);
    // console.log("month", month);
    // console.log(getFile);
    dispatch(addFileUpload(getFile, year, month));
  };

  return (
    <>
      <div className="datePick">
        {failUpload ? (
          <ColumnMapping setOpen={submit} />
        ) : (
          <>
            <ProgressBars steps={process} />
            <div className="progressName">
              <div>Document Upload</div>
              <div>Column Mapping</div>
              <div>Data Processing</div>
            </div>
            <DatePickers selectedDate="MonthYearUpload" />{" "}
            <DragDropFile
              uploadFileCall={uploadFileCall}
              setUploadFile={setUploadFile}
            />
          </>
        )}
      </div>

      {failUpload ? (
        ""
      ) : (
        <div className="buttonAlign">
          <Listbuttons
            disabled={uploadFileCall === "" ? true : false}
            data={"Submit"}
            className="data-button"
            onClick={afterUpload}
          />
        </div>
      )}
    </>
  );
}

export default UploadDocument;
