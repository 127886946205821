import React, { useState } from "react";
import "./dashboard.css";
import DownloadImgImage from "../../assets/images/download-rport.png";
import chart from "../../assets/images/chart.png";
import chart2 from "../../assets/images/chart2.png";
import chart3 from "../../assets/images/chart3.png";
import chart4 from "../../assets/images/chart4.png";
import search from "../../assets/images/search.png";
import DatePickers from "../Datepicker/datepicker";
import Loaders from "../Loader/Loader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import { getSalesReportDisplay } from "../../redux/actions/dashboard";
import { getExpenseReportDisplay } from "../../redux/actions/dashboard";

import CreditTabActiveOpen from "./creditTabOpen";
import DebitTabActiveOpen from "./debitTabOpen";

function Dashboard() {
  let dispatch = useDispatch();

  const [callTab, setCallTab] = useState("CreditTabActive");
  const goTocreditAndDebit = (value) => {
    console.log("goTocreditAndDebit", value);
    setCallTab(value);
  };
  const creditDebitTabActive = () => {
    switch (callTab) {
      case "DebitTabActive":
        return <DebitTabActiveOpen />;
      default:
        return <CreditTabActiveOpen />;
    }
  };

  const loadingState = useSelector((state) => state.fileUpload.loadingState);

  const salesData = useSelector(
    (state) => state.dashboardApiValue.getStatesData
  );

  const expenceData = useSelector(
    (state) => state.dashboardApiValue.getExpenseData
  );

  const getSalesReport = () => {
    const selectedDate = document.getElementById("MonthYearSales")?.value;
    console.log("salesReportDownload", selectedDate);
    var year = selectedDate.slice(3);
    var month = selectedDate.substring(0, 2);
    dispatch(getSalesReportDisplay(year, month));
  };
  const getexpenseReport = () => {
    const selectedDate = document.getElementById("MonthYearExpense")?.value;
    console.log("getExpenseReportDisplay", selectedDate);
    var year = selectedDate.slice(3);
    var month = selectedDate.substring(0, 2);
    dispatch(getExpenseReportDisplay(year, month));
  };
  // var dataSalesDownload = "";
  const salesReportDownload = () => {
    const selectedDate = document.getElementById("MonthYearSales")?.value;
    console.log("expenseReportDownload", selectedDate);
    var year = selectedDate.slice(3);
    var month = selectedDate.substring(0, 2);
    console.log("expenseReportDownload", year, month);
    var dataSalesDownload =
      "https://arvs-api.theretailinsights.co/api/v1/monthly_reports/MonthlySales?year=" +
      year +
      "&month=" +
      month;
    console.log("dataExpenceDownload", dataSalesDownload);
    const a = document.createElement("a");
    a.href = dataSalesDownload;
    a.download = dataSalesDownload.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const expenseReportDownload = () => {
    const selectedDate = document.getElementById("MonthYearExpense")?.value;
    console.log("expenseReportDownload", selectedDate);
    var year = selectedDate.slice(3);
    var month = selectedDate.substring(0, 2);
    console.log("expenseReportDownload", year, month);
    var dataExpenceDownload =
      "https://arvs-api.theretailinsights.co/api/v1/monthly_reports/MonthlyCDN?year=" +
      year +
      "&month=" +
      month;
    console.log("dataExpenceDownload", dataExpenceDownload);
    const a = document.createElement("a");
    a.href = dataExpenceDownload;
    a.download = dataExpenceDownload.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {loadingState ? <Loaders /> : ""}
      <div className="dashboard-page">
        <div className="dashboard-content">
          <div className="sales-expense">
            <div className="sales">
              <div className="sales_espence">
                <div className="sales-head">Sales</div>
              </div>
              <div>
                <div className="get-report">
                  <div className="buttons-get-month">
                    <DatePickers selectedDate="MonthYearSales" />
                    <div className="selectDate" onClick={getSalesReport}>
                      Get report
                    </div>
                  </div>
                  <div className="selectDate" onClick={salesReportDownload}>
                    Download
                  </div>
                </div>
              </div>
              <div className="calculation">
                <div className="evaluation">
                  <div className="price-field">
                    <div className="price">₹ {salesData.total_qty}</div>
                    <div>Total Sales Quantity</div>
                  </div>
                  <div className="pricelogo">
                    {/* <img
                      className="oval-img"
                      src={totalSale3}
                      alt="totalSale"
                      width="95px"
                      height="auto"
                    /> */}
                    <img
                      className="chart-img"
                      src={chart3}
                      alt="chart-img"
                      width="27px"
                      height="auto"
                    />
                  </div>
                </div>
                <div className="evaluation">
                  <div className="price-field">
                    <div className="price">₹ {salesData.total_mrp}</div>
                    <div>Total Sales MRP Value</div>
                  </div>
                  <div className="pricelogo">
                    {/* <img
                      className="oval-img"
                      src={totalSale}
                      alt="totalSale"
                      width="95px"
                      height="auto"
                    /> */}
                    <img
                      className="chart-img"
                      src={chart}
                      alt="chart-img"
                      width="27px"
                      height="auto"
                    />
                  </div>
                </div>

                <div className="evaluation">
                  <div className="price-field">
                    <div className="price">₹ {salesData.total_gsv}</div>
                    <div>Total Sales GSV Value</div>
                  </div>
                  <div className="pricelogo">
                    {/* <img
                      className="oval-img2"
                      src={totalSale2}
                      alt="oval-img2"
                      width="95px"
                      height="auto"
                    /> */}
                    <img
                      className="chart-img"
                      src={chart2}
                      alt="chart-img"
                      width="32px"
                      height="auto"
                    />
                  </div>
                </div>
              </div>
              <div className="calculation">
                <div className="evaluation">
                  <div className="price-field">
                    <div className="price">₹ {salesData.total_dis}</div>
                    <div>Total Discount Amount</div>
                  </div>
                  <div className="pricelogo">
                    {/* <img
                      className="oval-img"
                      src={totalSale3}
                      alt="totalSale"
                      width="95px"
                      height="auto"
                    /> */}
                    <img
                      className="chart-img"
                      src={chart3}
                      alt="chart-img"
                      width="27px"
                      height="auto"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sales">
              <div className="sales_espence">
                <div className="sales-head">Expense</div>
              </div>
              <div className="get-report">
                <div className="buttons-get-month">
                  <div className="expece-date-picker-allign">
                    <DatePickers selectedDate="MonthYearExpense" />
                  </div>
                  <div className="selectDate" onClick={getexpenseReport}>
                    Get report
                  </div>
                </div>
                <div className="selectDate" onClick={expenseReportDownload}>
                  Download
                </div>
              </div>
              <div className="calculation">
                <div className="evaluation">
                  <div className="price-field">
                    <div className="price">₹ {expenceData.sales_qty}</div>
                    <div>Total Sales Quantity</div>
                  </div>
                  <div className="pricelogo">
                    {/* <img
                      className="oval-img3"
                      src={totalSale3}
                      alt="oval-img3"
                      width="95px"
                      height="auto"
                    /> */}
                    <img
                      className="chart-img"
                      src={chart3}
                      alt="chart-img"
                      width="30px"
                      height="auto"
                    />
                  </div>
                </div>
                <div className="evaluation">
                  <div className="price-field">
                    <div className="price">
                      ₹ {expenceData.credit_total_mrp}
                    </div>
                    <div>Total Credit Amount</div>
                  </div>
                  <div className="pricelogo">
                    {/* <img
                      className="oval-img"
                      src={totalSale}
                      alt="totalSale"
                      width="95px"
                      height="auto"
                    /> */}
                    <img
                      className="chart-img"
                      src={chart}
                      alt="chart-img"
                      width="27px"
                      height="auto"
                    />
                  </div>
                </div>
                <div className="evaluation">
                  <div className="price-field">
                    <div className="price">₹{expenceData.debit_total_mrp}</div>
                    <div>Total Debit Amount</div>
                  </div>
                  <div className="pricelogo">
                    {/* <img
                      className="oval-img4"
                      src={totalSale4}
                      alt="oval-img4"
                      width="95px"
                      height="auto"
                    /> */}
                    <img
                      className="chart-img"
                      src={chart4}
                      alt="chart-img"
                      width="34px"
                      height="auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="credit">
            <div className="vendor-credit">
              <div className="credit-parent">
                <div
                  className={
                    callTab === "DebitTabActive" ? "tab-oppDis" : "tab-oppEna"
                  }
                >
                  <div
                    className="creditTab"
                    onClick={() => goTocreditAndDebit("CreditTabActive")}
                  >
                    <span class="title">View Credit</span>
                    <span class="curve"></span>
                  </div>
                </div>
                <div
                  className={
                    callTab === "CreditTabActive" ? "tab-oppDis" : "tab-oppEna"
                  }
                >
                  <div
                    className="debitTab"
                    onClick={() => goTocreditAndDebit("DebitTabActive")}
                  >
                    <span class="title">View Debit</span>
                    <span class="curve"></span>
                  </div>
                </div>
              </div>
            </div>
            {creditDebitTabActive()}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
