import * as types from "../actionTypes";
import axios from "axios";
import Toast from "../../components/Popup/Popup";

const loadersCall = (data) => ({
  type: types.LOADERS_CALL,
  payload: data,
});

const getScheme = (data) => ({
  type: types.GET_SCHEME_DATA,
  payload: data,
});
const getCreditDebit = (data) => ({
  type: types.GET_CREDIT_DEBIT_DATA,
  payload: data,
});
export const getSchemeDataValue = () => {
  console.log("resPraveen");
  return function (dispatch) {
    dispatch(loadersCall(true));

    axios
      .get("https://arvs-api.theretailinsights.co/api/v1/schemestats")
      .then((resp) => {
        dispatch(getScheme(resp.data));
        dispatch(loadersCall(false));
        Toast("Done fetching data", "success");
      })
      .catch((error) => {
        dispatch(loadersCall(false));
        console.log(error);
        Toast("Error", "error");
      });
  };
};

export const getCreditDebitDataValue = () => {
  console.log("resPraveen");
  return function (dispatch) {
    dispatch(loadersCall(true));

    axios
      .get("https://arvs-api.theretailinsights.co/api/v1/schemecdn")
      .then((resp) => {
        dispatch(getCreditDebit(resp.data));
        dispatch(loadersCall(false));
        Toast("Done fetching data", "success");
      })
      .catch((error) => {
        dispatch(loadersCall(false));
        console.log(error);
        Toast("Error", "error");
      });
  };
};
