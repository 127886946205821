import React, { useEffect, useState } from "react";
import "../SchemeTab/Scheme.css";
import "./Credit.css";
import Amount from "../../assets/images/TotalAmount.png";
import Sales from "../../assets/images/TotalSales.png";
import System from "../../assets/images/TotalSystem.png";
import download from "../../assets/images/report.png";
// import document from "../../assets/images/success.png"
import { useDispatch, useSelector } from "react-redux";
import { getCreditDebitDataValue } from "../../redux/actions/scheme";
import Loaders from "../Loader/Loader";
import "./Credit.css";
function Credit() {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCreditDebitDataValue());
  }, []);

  const getCreditDebitFromApi = useSelector(
    (state) => state.schemeApiValue.getCreditDebitData
  );

  const getFileId = useSelector((state) => state.fileUpload.getFileId);
  console.log("getFileId", getFileId);

  const fileDownloadCredit =
    "https://arvs-api.theretailinsights.co/api/v1/reports/credit/";

  const fileDownloadDebit =
    "https://arvs-api.theretailinsights.co/api/v1/reports/debit/";

  const forCredit = fileDownloadCredit + getFileId;

  const forDebit = fileDownloadDebit + getFileId;

  const loadingState = useSelector((state) => state.fileUpload.loadingState);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      {loadingState ? <Loaders /> : ""}
      <div className="nav-tab-disable-for-hide"></div>
      <div className="nav-tab-disable-for-hide2"></div>
      <div className="container">
        <div className="scheme-data">
          <p>Overall Credit</p>
          <p>
            <b>Rs. {getCreditDebitFromApi.total_sales_credit}</b>
          </p>
          <img src={Sales} alt="Sales" />
        </div>
        <div className="scheme-data">
          <p>Overall Debit</p>
          <p>
            <b>Rs. {getCreditDebitFromApi.total_sales_debit}</b>
          </p>
          <img src={System} alt="System" />
        </div>
        <div className="scheme-data">
          <p>Total Value</p>
          <p>
            <b>Rs.{getCreditDebitFromApi.total}</b>
          </p>
          <img src={Amount} alt="Amount" />
        </div>
      </div>
      <div className="contain">
        <div className="card">
          <img className="schemeDownloadImage" src={download} alt="Sales" />
          <p>Download the Credit Report !</p>
          <button class="btn">
            <a href={forCredit} download>
              <p>Download</p>
            </a>
          </button>
        </div>
        <div className="card">
          <img className="schemeDownloadImage" src={download} alt="Sales" />
          <p>Download the Debit Report !</p>
          <button class="btn">
            <a href={forDebit} download>
              <p>Download</p>
            </a>
          </button>
        </div>
      </div>

      <button onClick={refreshPage} className="refresh">
        Back to Dashboard
      </button>
    </>
  );
}

export default Credit;
