import * as types from "../actionTypes";
import axios from "axios";
import Toast from "../../components/Popup/Popup";

const getSalesData = (data) => ({
  type: types.GET_SALES_DETAILS,
  payload: data,
});

const getExpenceData = (data) => ({
  type: types.GET_EXPENSE_DETAILS,
  payload: data,
});

const loadersCall = (data) => ({
  type: types.LOADERS_CALL,
  payload: data,
});

const getScheme = (data) => ({
  type: types.GET_SCHEME_DATA,
  payload: data,
});

export const getSalesReportDisplay = (year, month) => {
  // console.log("hgdfjsgdjf", photoFile, photoFile.name);
  const formData = new FormData();
  formData.append("year", year);
  formData.append("month", month);
  // console.log("formData", formData);

  return function (dispatch) {
    dispatch(loadersCall(true));
    axios

      .post(
        "https://arvs-api.theretailinsights.co/api/v1/salesdashboard",
        formData
      )

      .then((resp) => {
        dispatch(getSalesData(resp.data));
        dispatch(loadersCall(false));
        Toast("Done fetching data", "success");
      })
      .catch((error) => {
        dispatch(loadersCall(false));
        console.log(error);
        Toast("Error fetching data", "error");
      });
  };
};

export const getExpenseReportDisplay = (year, month) => {
  // console.log("hgdfjsgdjf", photoFile, photoFile.name);
  const formData = new FormData();
  formData.append("year", year);
  formData.append("month", month);
  // console.log("formData", formData);

  return function (dispatch) {
    dispatch(loadersCall(true));
    axios

      .post("https://arvs-api.theretailinsights.co/api/v1/cd", formData)
      .then((resp) => {
        dispatch(getExpenceData(resp.data));
        dispatch(loadersCall(false));
        Toast("Done fetching data", "success");
      })
      .catch((error) => {
        dispatch(loadersCall(false));
        console.log(error);
        Toast("Error fetching data", "error");
      });
  };
};
