import * as types from "../actionTypes";
import axios from "axios";
import Toast from "../../components/Popup/Popup";
// import { loadColumnMapping } from "./columnMapping";

export const getFileUpload = (dataFile) => ({
  type: types.GET_UPLOAD_FILE,
  payload: dataFile,
});

export const uploadApiResponse = (dataFile) => ({
  type: types.UPLOAD_API_RESPONSE,
  payload: dataFile,
});

const uploadFile = () => ({
  type: types.UPLOAD_FILE,
});

const loadersCall = (data) => ({
  type: types.LOADERS_CALL,
  payload: data,
});

const storeFileId = (data) => ({
  type: types.STORE_FILE_ID,
  payload: data,
});

export const addFileUpload = (data, year, month) => {
  // console.log("datadata", data);
  // console.log("year", year);
  // console.log("month", month);
  const photoFile = data;
  // console.log("hgdfjsgdjf", photoFile, photoFile.name);
  const formData = new FormData();
  formData.append("file", photoFile, photoFile.name);
  formData.append("year", year);
  formData.append("month", month);
  // console.log("formData", formData);

  return function (dispatch) {
    dispatch(loadersCall(true));
    const accessToken = localStorage.getItem("arvindLogin");
    axios

      .post(
        "https://arvs-api.theretailinsights.co/api/v1/file/upload",
        formData,
        accessToken
      )

      .then((resp) => {
        dispatch(uploadFile(resp.data));
        dispatch(loadersCall(false));
        dispatch(storeFileId(resp.data.file_id));
        dispatch(uploadApiResponse(true));
        Toast("File Uploaded successfully", "success");
      })
      .catch((error) => {
        dispatch(uploadApiResponse(false));
        dispatch(loadersCall(false));
        console.log(error);
        Toast("Please Upload valid file & Select month and Year", "error");
      });
  };
};
