/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import "./DocumentUpload.css";
import Listbuttons from "../Buttons/buttons";
import UploadDocument from "./DocumentUpload";
import ProgressBars from "../StatusBar/statusbar";
import ProcessingTab from "./dataprocessing";
import { useDispatch, useSelector } from "react-redux";
import { loadColumnMapping } from "../../redux/actions/columnMapping";
import { sendSelectedValue } from "../../redux/actions/columnMapping";
import { uploadApiResponse } from "../../redux/actions/uploadFile";
import Loaders from "../Loader/Loader";

function ColumnMapping() {
  let dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const fileId = useSelector((state) => state.fileUpload.getFileId);

  const [formattedArray, setFormateArray] = useState([]);
  let [selectedValueInDropown, setSelectedValue] = useState({});
  console.log("selectedValueInDropown", selectedValueInDropown);
  const mapping = useSelector((state) => state.mappingColumn.mapping);
  const dropDownFail = useSelector(
    (state) => state.dropDownValue.dropDownNotSelect
  );
  const loadingState = useSelector((state) => state.fileUpload.loadingState);

  const backToUpload = () => {
    dispatch(uploadApiResponse(false));
  };

  const goToSucess = () => {
    console.log(formattedArray);
    // const addresses = [...selectedValueInDropown]; // Some array I got from async call

    // const uniqueAddresses = Array.from(new Set(addresses.map((a) => a.id))).map(
    //   (id) => {
    //     return addresses.find((a) => a.id === id);
    //   }
    // );
    dataKeys.forEach((element) => {
      if (Object.entries(selectedValueInDropown).length === 0) {
        if (!(element in selectedValueInDropown)) {
          selectedValueInDropown[element] = mapping[element][0];
        }
      } else {
        selectedValueInDropown[element] = mapping[element][0];
      }
    });
    console.log("selectedValueInDropown", selectedValueInDropown);

    dispatch(sendSelectedValue(selectedValueInDropown, fileId));
  };

  useEffect(() => {
    dispatch(loadColumnMapping(fileId));
  }, []);

  const dropdownChangeHandler = (e) => {
    setSelectedValue({
      ...selectedValueInDropown,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  //   if (dataKeys && dataKeys.length) {
  //     var SelectedIfEmpty = {};
  //     dataKeys.map((item) => {
  //       if (item.arrayData) {
  //         SelectedIfEmpty[item.value] = item.arrayData[0];
  //       }
  //     });
  //     setSelectedValue(SelectedIfEmpty);
  //   }
  // }, [formattedArray]);

  const dataKeys = Object.keys(mapping);
  return (
    <>
      {loadingState ? <Loaders /> : ""}
      {dropDownFail ? <ProcessingTab /> : ""}
      {submit ? (
        <UploadDocument />
      ) : (
        <>
          {dropDownFail ? (
            ""
          ) : (
            <>
              <ProgressBars steps={50} />
              <div className="progressName">
                <div>Document Upload</div>
                <div>Column Mapping</div>
                <div>Data Processing</div>
              </div>
              <div className="upload_header">
                <div className="header-text">
                  Please find the Column Mapping & do the respective changes.
                </div>
              </div>

              <div className="uploaddetails">
                <div className="uploadDetail">System Column </div>
                <div className="uploadDetail">Excel Column </div>
              </div>
              <div className="upload-scroll">
                {dataKeys.map((item) => (
                  <div className="fileUpload">
                    <div className="columnDetails">{item}</div>
                    <div className="columnDetails">
                      <form>
                        <select
                          className="selectUploadMapping"
                          name={item}
                          onChange={dropdownChangeHandler}
                          value={selectedValueInDropown[item] || ""}
                        >
                          {mapping[item]?.map((label) => (
                            <option key={label}>{label}</option>
                          ))}
                        </select>
                      </form>
                    </div>
                  </div>
                ))}
              </div>

              <div className="btn-upload">
                <Listbuttons
                  onClick={goToSucess}
                  data={"Save"}
                  className="data-button"
                />

                <div className="cancel">
                  <button className="cancel-btn" onClick={backToUpload}>
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ColumnMapping;
