import React from "react";
import Loader from "react-js-loader";
import "./Loader.css";

function Loaders() {
  return (
    <div className="LoaderView">
      <div className="Loader">
        <div className={"row"}>
          <div className={"item"}>
            <Loader
              type="spinner-cub"
              bgColor={"#595959"}
              title={"Loading..."}
              color={"#pink"}
              size={100}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loaders;
