import * as types from "../actionTypes";
import axios from "axios";
import Toast from "../../components/Popup/Popup";

const getColumn = (data) => ({
  type: types.GET_COLUMN_MAPPING,
  payload: data,
});

const sendValue = (data) => ({
  type: types.UPLOAD_DATA_PROCESSING,
  payload: data,
});

export const dropDownEmpty = (dataFile) => ({
  type: types.SELECTED_DROPDOWN_RESPONSE,
  payload: dataFile,
});

const loadersCall = (data) => ({
  type: types.LOADERS_CALL,
  payload: data,
});

const enableTab = (data) => ({
  type: types.TAB_ENABLE,
  payload: data,
});

export const loadColumnMapping = (fileId) => {
  console.log("res", fileId);
  return function (dispatch) {
    dispatch(loadersCall(true));
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .get(
        `https://arvs-api.theretailinsights.co/api/v1/headers/${fileId}`,
        options
      )
      .then((resp) => {
        dispatch(getColumn(resp.data));
        dispatch(loadersCall(false));
      })
      .catch((error) => {
        dispatch(loadersCall(false));
        console.log(error);
      });
  };
};

export const sendSelectedValue = (data, fileId) => {
  return function (dispatch) {
    dispatch(loadersCall(true));
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://arvs-api.theretailinsights.co/api/v1/data/ingest/${fileId}`,
        data,
        options
      )
      .then((resp) => {
        dispatch(sendValue(resp.data));
        dispatch(loadersCall(false));
        Toast("DataProcessing Is done", "success");
        dispatch(dropDownEmpty(true));
        dispatch(enableTab(true));
      })
      .catch((error) => {
        console.log(error);
        dispatch(dropDownEmpty(false));
        dispatch(loadersCall(false));
        Toast("Error updating the data", "error");
        dispatch(enableTab(false));
      });
  };
};
