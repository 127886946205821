import * as types from "../actionTypes";

const initialState = {
  upload: [],
  getFile: {},
  loader: false,
  loading: true,
  loadingState: false,
  getFileId: "",
  logoutPopupAction: false,
};

export const uploadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_FILE:
      return {
        ...state,
        upload: action.payload,
        loading: false,
      };
    case types.GET_UPLOAD_FILE:
      return {
        ...state,
        getFile: action.payload,
        loading: false,
      };
    case types.UPLOAD_API_RESPONSE:
      return {
        ...state,
        failUpload: action.payload,
        loading: false,
      };
    case types.SELECTED_DROPDOWN_RESPONSE:
      return {
        ...state,
        loader: action.payload,
        loading: false,
      };
    case types.LOADERS_CALL:
      return {
        ...state,
        // getFile: action.payload,
        loadingState: action.payload,
      };
    case types.STORE_FILE_ID:
      return {
        ...state,
        getFileId: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
