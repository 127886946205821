import * as types from "../actionTypes";

const initialState = {
  getStatesData: {},
  getExpenseData: {},
  getSchemeData: {},
};

export const dashboardApi = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SALES_DETAILS:
      // console.log("GET_COLUMN_MAPPING", action.payload);
      return {
        ...state,
        getStatesData: action.payload,
        loading: false,
      };

    case types.GET_EXPENSE_DETAILS:
      // console.log("GET_COLUMN_MAPPING", action.payload);
      return {
        ...state,
        getExpenseData: action.payload,
        loading: false,
      };

    case types.GET_SCHEME_DATA:
      // console.log("GET_COLUMN_MAPPING", action.payload);
      return {
        ...state,
        getSchemeData: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
