import * as types from "../actionTypes";

const initialState = {
  download: {},
  loading: true,
};

export const downloadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COLUMN_MAPPING:
      // console.log("DOWNLOAD_UPLOADED_FILE", action.payload);
      return {
        ...state,
        download: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
