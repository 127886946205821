import React, { useEffect, useState } from "react";
import "./App.css";
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
//import LeftContent from "./components/dashboardleft/leftcontent";
//import { BrowserRouter as Router } from "react-router-dom";
//import HeaderTab from "./components/Heading/HeaderTab"
import Login from "./components/LoginPage/Login";
import Dashboard from "./components/Dashboard/dashboard";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  // Link,
  // NavLink,
  // useLocation,
} from "react-router-dom";
//import LeftContent from "./components/MainContent/leftcontent";
import { ToastContainer } from "react-toastify";
import LeftContent from "./components/MainContent/leftcontent";
// import {BrowserRouter, Route, Routes} from "react-router-dom"
//import { BrowserRouter as Router  } from "react-router-dom";

const isLogin = () => {
  if (localStorage.getItem("arvindLogin")) {
    return true;
  } else {
    return false;
  }
};

function App() {
  const [token, setToken] = useState();
  let navigate = useNavigate();
  // const ProtectedRoute = ({ children }) => {
  //   const { token } = useAuth();
  //   const location = useLocation();

  //   if (!token) {
  //     return <Navigate to="/" replace state={{ from: location }}  />;
  //   }

  //   return children;
  // };

  // if(!token) {
  //   return <Login setToken={setToken}/>;
  // }

  // if(localStorage.getItem("dashboard")=="200"){
  //   console.log("hello called",localStorage.getItem("dashboard"))
  //   return <HeaderTab/>
  // }
  // console.log("hello called",localStorage.getItem("dashboard"))

  useEffect(() => {
    if (localStorage.getItem("arvindLogin")) {
      navigate("/dashboard", { replace: true });
    }
  }, [localStorage.getItem("arvindLogin")]);

  return (
    // <div>
    //   <div className="App">
    //      <HeaderTab />
    //     <LeftContent />
    //      <Login />
    //   </div>
    // </div>

    <div className="App">
      <ToastContainer />

      {/* <Routes>
        <Route index element={<Login />} />
         <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><LeftContent /></PrivateRoute>} />
          <Route path="*" element={<p>There's nothing here: 404 page not found!</p>} />
      </Routes> */}

      <Routes>
        <Route path="/*" element={<Login />} />
        <Route
          element={isLogin() ? <LeftContent /> : <Navigate to="/" />}
          path="/dashboard"
        />
        <Route
          path="*"
          element={<p>There's nothing here: 404 page not found!</p>}
        ></Route>
      </Routes>
    </div>
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
