import * as types from "../actionTypes";
import axios from "axios";
import Toast from "../../components/Popup/Popup";

const loadersCall = (data) => ({
  type: types.LOADERS_CALL,
  payload: data,
});

const storeHistoryRes = (data) => ({
  type: types.HISTORY_API_RESPONCE,
  payload: data,
});

export const sendYearAndMonthForHistory = (year, month) => {
  console.log("data", year, month);
  const getHistory = {
    year: year,
    month: month,
    rate: 490,
    start: 100,
  };
  console.log("getHistory", getHistory);
  // const options = {
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // };
  return function (dispatch) {
    dispatch(loadersCall(true));
    console.log("loadersCall", loadersCall);

    axios
      .get(
        `https://arvs-api.theretailinsights.co/api/v1/sales/history?year=${getHistory.year}&month=${getHistory.month}&rate=${getHistory.rate}&start=${getHistory.start}`
      )
      .then((resp) => {
        dispatch(loadersCall(false));
        Toast("Fetching data", "success");
        dispatch(storeHistoryRes(resp.data));
      })
      .catch((error) => {
        dispatch(loadersCall(false));
        Toast("Error Fetching data", "error");
      });
  };
};
