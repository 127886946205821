import React, { useRef, useState } from "react";
import "./fileupload.css";
import uploadImage from "../../assets/images/upload.png";
import { useDispatch, useSelector } from "react-redux";
import { getFileUpload } from "../../redux/actions/uploadFile";
import logo from "../../assets/images/ms-logo.png";
import Loaders from "../Loader/Loader";

function DragDropFile({ setUploadFile, uploadFileCall, text }) {
  const fileInputRef = useRef();
  let dispatch = useDispatch();
  // drag state
  const loadingState = useSelector((state) => state.fileUpload.loadingState);
  // console.log("loadingState", loadingState);
  const [dragActive, setDragActive] = useState(false);
  const [textChange, setTesxtChange] = useState("");

  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    // console.log("test", e);
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const eventObject = {
        target: { files: e.dataTransfer.files, value: e.dataTransfer.value },
      };
      changeFileHandler(eventObject);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      changeFileHandler(e);
    }
  };

  const deleteUploadedFile = () => {
    setTesxtChange("");
    setUploadFile("");
  };

  // triggers the input when the button is clicked
  const changeFileHandler = (event) => {
    // console.log("files", event.target);
    // let file = event.target.value.split("\\");
    // console.log(event.target.files[0]);
    let fileObj = event.target.files[0];
    // console.log("fileObj", fileObj);
    setUploadFile(fileObj);
    dispatch(getFileUpload(fileObj));
    // console.log("testtoday", event.target.files[0].name);
    setTesxtChange(event.target.files[0].name);
// this is the code commented

  //    dispatch(addFileUpload(fileObj));
  //    console.log("file", file1);

  //    let file1 = file1.split("\\");
  //    console.log("file format",file1);
  //    let file2 = file1[file1.length - 1];
  //    console.log('file2',file2);
  //    const formData = new FormData();
  //     console.log("formData", formData);
  //    formData.append('file',file2,file2.name);
  //    const filess = {
  //      file:file2
  //    }
  //    const fileExtension = event.target.files[0].name.split(".").pop();
  //    console.log("filepath", fileExtension);
  //    if (fileExtension === "xlsx") {
  //      const formData = new FormData();
  //      console.log("formData", formData);
  //    formData.append(event.target.files[0], event.target.files[0].name);

    }
  // };
  //  console.log("setFile", file);

  //end of commented code

  return (
    <div>
      {loadingState ? <Loaders /> : ""}
      {textChange === "" ? (
        <form
          id="form-file-upload"
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={false}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
          >
            <div>
              <div>
                <img
                  className="profile-image"
                  src={uploadImage}
                  alt="ProfileImage"
                  width="20px"
                  height="auto"
                />
              </div>

              <button
                className="uploadButton"
                onClick={() => fileInputRef.current.click()}
              >
                Drag and drop or <span className="browserColor">Browse</span> to
                upload
              </button>
              <input
                onChange={changeFileHandler}
                ref={fileInputRef}
                multiple={true}
                type="file"
                name="file"
                hidden
              />
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>
      ) : (
        <div className="text-filename">
          <img
            className="ms-logo"
            src={logo}
            alt="ms-logo"
            width="20px"
            height="auto"
          />
          {textChange}{" "}
          <button className="closeButton" onClick={deleteUploadedFile}>
            <span className="delete">Delete</span> X
          </button>
        </div>
      )}
    </div>
  );
}

export default DragDropFile;
