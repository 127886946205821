export const UPLOAD_FILE = "UPLOAD_FILE";

export const GET_COLUMN_MAPPING = "GET_COLUMN_MAPPING";

export const UPLOAD_DATA_PROCESSING = "UPLOAD_DATA_PROCESSING";

export const GET_UPLOAD_FILE = "GET_UPLOAD_FILE";

export const UPLOAD_API_RESPONSE = "UPLOAD_API_RESPONSE";

export const SELECTED_DROPDOWN_RESPONSE = "SELECTED_DROPDOWN_RESPONSE";

export const DOWNLOAD_UPLOADED_FILE = "DOWNLOAD_UPLOADED_FILE";

export const LOADER = "LOADER";

export const LOADERS_CALL = "LOADERS_CALL";

export const STORE_FILE_ID = "STORE_FILE_ID";

export const DEAL_MASTER_UPLOAD = "DEAL_MASTER_UPLOAD";

export const MARGIN_MASTER_UPLOAD = "MARGIN_MASTER_UPLOAD";

export const TAB_ENABLE = "TAB_ENABLE";

export const HISTORY_API = "HISTORY_API";

export const HISTORY_API_RESPONCE = "HISTORY_API_RESPONCE";

export const GET_SALES_DETAILS = "GET_SALES_DETAILS";

export const GET_EXPENSE_DETAILS = "GET_EXPENSE_DETAILS";

export const GET_SCHEME_DATA = "GET_SCHEME_DATA";

export const GET_CREDIT_DEBIT_DATA = "GET_CREDIT_DEBIT_DATA";
