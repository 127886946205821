import * as types from "../actionTypes";
import axios from "axios";
import Toast from "../../components/Popup/Popup";

export const getDealsFileUpload = (dataFile) => ({
  type: types.DEAL_MASTER_UPLOAD,
  payload: dataFile,
});

export const getMarginFileUpload = (dataFile) => ({
  type: types.MARGIN_MASTER_UPLOAD,
  payload: dataFile,
});

export const uploadApiResponse = (dataFile) => ({
  type: types.UPLOAD_API_RESPONSE,
  payload: dataFile,
});

const loadersCall = (data) => ({
  type: types.LOADERS_CALL,
  payload: data,
});

export const dealsMasterUpload = (data) => {
  // console.log("dealsMasterUpload", data);
  const photoFile = data;
  // console.log("hgdfjsgdjf", photoFile, photoFile.name);
  const formData = new FormData();
  formData.append("file", photoFile, photoFile.name);
  // console.log("formData", formData);

  return function (dispatch) {
    dispatch(loadersCall(true));

    axios
      .post(
        "https://arvs-api.theretailinsights.co/api/v1/deals/master",
        formData
      )

      .then((resp) => {
        dispatch(getDealsFileUpload(resp.data));
        dispatch(loadersCall(false));
        dispatch(uploadApiResponse(true));
        Toast("File Uploaded successfully", "success");
      })
      .catch((error) => {
        dispatch(uploadApiResponse(false));
        dispatch(loadersCall(false));
        console.log(error);
        Toast("Please Upload valid file & Select the drop down", "error");
      });
  };
};

export const marginMasterUpload = (data) => {
  // console.log("marginMasterUpload", data);
  const photoFile = data;
  // console.log("hgdfjsgdjf", photoFile, photoFile.name);
  const formData = new FormData();
  formData.append("file", photoFile, photoFile.name);
  // console.log("formData", formData);

  return function (dispatch) {
    dispatch(loadersCall(true));

    axios
      .post(
        "https://arvs-api.theretailinsights.co/api/v1/margin/master",
        formData
      )

      .then((resp) => {
        dispatch(getMarginFileUpload(resp.data));
        dispatch(loadersCall(false));
        dispatch(uploadApiResponse(true));
        Toast("File Uploaded successfully", "success");
      })
      .catch((error) => {
        dispatch(uploadApiResponse(false));
        dispatch(loadersCall(false));
        console.log(error);
        Toast("Please Upload valid file & Select the drop down", "error");
      });
  };
};
