import * as types from "../actionTypes";

const initialState = {
  getSchemeData: {},
  getCreditDebitData: {},
};

export const schemeApi = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SCHEME_DATA:
      // console.log("GET_COLUMN_MAPPING", action.payload);
      return {
        ...state,
        getSchemeData: action.payload,
        loading: false,
      };
    case types.GET_CREDIT_DEBIT_DATA:
      // console.log("GET_COLUMN_MAPPING", action.payload);
      return {
        ...state,
        getCreditDebitData: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
