import React, { useState, useEffect } from "react";
import "./LeftContent.css";
import arvind from "../../assets/images/arvind.png";
import menu1 from "../../assets/images/menu1.png";
import menu2 from "../../assets/images/menu2.png";
import menu3 from "../../assets/images/menu3.png";
import dashboardicon from "../../assets/images/dashboard.png";
// import processIcon from "../../assets/images/process-active.png";
import processActive from "../../assets/images/Vector.png";
import history from "../../assets/images/history.png";
import profileImage from "../../assets/images/profile.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import ProgressBars from "../statusBar/statusbar";
// import ColumnMapping from "../dataprocess/columnMapping";
import UploadDocument from "../Dataprocess/DocumentUpload";
import master from "../../assets/images/master.png";
import masterActive from "../../assets/images/master-active.png";
import Scheme from "../SchemeTab/Scheme";
import Credit from "../CreditTab/Credit";
import Dashboard from "../Dashboard/dashboard";
import ProcessActiveImage from "../../assets/images/process-active.png";
import historyActive from "../../assets/images/history-active.png";
import MasterUpload from "../MasterUpload/MasterUpload";
import History from "../History/History";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreditDebitTab from "../CreditDebit/creditDebitTab";

const LeftContent = () => {
  let navigate = useNavigate();
  const [callPage, setCallPage] = useState("Dashboard");

  const [userName, stuserName] = useState("");

  const [logOutTab, setlogOutTab] = useState(false);
  const goToDashboardPage = (value) => {
    // console.log("GoToDashboard", value);
    setCallPage(value);
  };
  const logOutTabCall = () => {
    setlogOutTab(!logOutTab);
    // console.log(logOutTab);
  };
  const tabEnableScreen = useSelector((state) => state.processData.tabEnable);

  useEffect(() => {
    if (localStorage.getItem("userName") === "undefined") {
      navigate("/login", { replace: true });
    } else {
      stuserName(JSON.parse(localStorage.getItem("userName") || ""));
    }
  }, []);
  // console.log("tabEnableScreen", tabEnableScreen);

  const getCurrentComp = () => {
    switch (callPage) {
      case "masterUpload":
        return <MasterUpload />;
      case "dataProcess":
        return <UploadDocument />;
      case "history":
        return <History />;
      case "creditDebit":
        return <CreditDebitTab />;
      default:
        return <Dashboard />;
    }
  };

  const navToLoginPage = () => {
    // console.log("LogOut");
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  return (
    <>
      <div className="App">
        <Tabs>
          <TabList>
            <div className="logo">
              <img
                className=""
                src={arvind}
                alt="arvind logo"
                width="150px"
                height="auto"
              />
            </div>
            <Tab>
              <div>
                <div className="menu-icons">
                  <img
                    className="menu-image"
                    src={menu1}
                    alt="menu1"
                    width="50px"
                    height="auto"
                  />
                  <div className="menu-bg1"></div>
                  <div className="menu-bg">
                    <div className="menu-text">Sales Data</div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab>
              <div
                className={
                  tabEnableScreen === true ? "tab-enable" : "tab-disable"
                }
              >
                <div className="menu-icons">
                  <img
                    className="menu-image"
                    src={menu2}
                    alt="menu2"
                    width="50px"
                    height="auto"
                  />
                  <div className="menu-bg1"></div>
                  <div className="menu-bg">
                    <div className="menu-text">Scheme's Applied</div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab>
              <div
                className={
                  tabEnableScreen === true ? "tab-enable" : "tab-disable"
                }
              >
                <div className="menu-icons">
                  <img
                    className="menu-image"
                    src={menu3}
                    alt="menu3"
                    width="50px"
                    height="auto"
                  />
                  <div className="menu-bg1"></div>
                  <div className="menu-bg">
                    <div className="menu-text">Credit/Debit Note</div>
                  </div>
                </div>
              </div>
            </Tab>
            {/* <Tab>
              <p>Title 4</p>
            </Tab>
            <Tab>
              <p>Title 5</p>
            </Tab> */}
          </TabList>
          <div className="header">
            <div className="topL-menu">
              <div className="navItems">
                <div
                  className={
                    callPage === "Dashboard" ? "nav-item" : "nav-active"
                  }
                  onClick={() => goToDashboardPage("Dashboard")}
                >
                  <img
                    className="nav-icon"
                    src={dashboardicon}
                    alt="dashboardicon"
                    width="12px"
                    height="auto"
                  />
                  Dashboard
                </div>

                <div
                  className={
                    callPage === "dataProcess" ? "nav-item" : "nav-active"
                  }
                  onClick={() => goToDashboardPage("dataProcess")}
                >
                  {callPage === "dataProcess" ? (
                    <img
                      className="nav-icon"
                      src={processActive}
                      alt="processActive"
                      width="11px"
                      height="auto"
                    />
                  ) : (
                    <img
                      className="nav-icon"
                      src={ProcessActiveImage}
                      alt="processActiveImage"
                      width="11px"
                      height="auto"
                    />
                  )}
                  Data Process
                </div>

                <div
                  className={callPage === "history" ? "nav-item" : "nav-active"}
                  onClick={() => goToDashboardPage("history")}
                >
                  {callPage === "history" ? (
                    <img
                      className="nav-icon"
                      src={historyActive}
                      alt="historyActive"
                      width="16px"
                      height="auto"
                    />
                  ) : (
                    <img
                      className="nav-icon"
                      src={history}
                      alt="history"
                      width="16px"
                      height="auto"
                    />
                  )}
                  History
                </div>

                <div
                  className={
                    callPage === "masterUpload" ? "nav-item" : "nav-active"
                  }
                  onClick={() => goToDashboardPage("masterUpload")}
                >
                  {callPage === "masterUpload" ? (
                    <img
                      className="nav-icon"
                      src={masterActive}
                      alt="masterActive"
                      width="14px"
                      height="auto"
                    />
                  ) : (
                    <img
                      className="nav-icon"
                      src={master}
                      alt="master"
                      width="14px"
                      height="auto"
                    />
                  )}
                  Master Upload
                </div>
                {/* <div
                  className={
                    callPage === "creditDebit" ? "nav-item" : "nav-active"
                  }
                  onClick={() => goToDashboardPage("creditDebit")}
                >
                  {callPage === "creditDebit" ? (
                    <img
                      className="nav-icon"
                      src={masterActive}
                      alt="masterActive"
                      width="14px"
                      height="auto"
                    />
                  ) : (
                    <img
                      className="nav-icon"
                      src={master}
                      alt="master"
                      width="14px"
                      height="auto"
                    />
                  )}
                  Credit/Debit
                </div> */}
              </div>
            </div>
            <div className="topR-menu" onClick={logOutTabCall}>
              <div>
                <img
                  className="profile-image"
                  src={profileImage}
                  alt="profileImage"
                  width="40px"
                  height="auto"
                />
              </div>
              <div className="userName">{userName}</div>
              <div
                className={logOutTab === true ? "logout-show" : "logout-hide"}
              >
                <div className="logOutTab">
                  <div className="profile">Profile</div>
                  <div className="profile log-color" onClick={navToLoginPage}>
                    Logout
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TabPanel>
            <div className="panel-content">
              <div className="sales-panel">
                <div className="sales-panelshadow">
                  <div className="sales-conetent">{getCurrentComp()}</div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="panel-content">
              <div className="sales-panel">
                <div className="sales-panelshadow">
                  <div className="sales-conetent">
                    <Scheme />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="panel-content">
              <div className="sales-panel">
                <div className="sales-panelshadow">
                  <div className="sales-conetent">
                    <Credit />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          {/* {callPage === "Dashboard" ? <Dashboard /> : ""} */}
          {/* <TabPanel>
            <div className="panel-content">
              <h2>Any content 4</h2>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="panel-content">
              <h2>Any content 5</h2>
            </div>
          </TabPanel> */}
        </Tabs>
      </div>
    </>
  );
};

export default LeftContent;
