import React, { useState, useEffect } from "react";
import ProgressBars from "../StatusBar/statusbar";
// import { uploadDataProcessing } from "../../redux/actions/dataProcessing";
import save from "../../assets/images/column-mapping.gif";
import download from "../../assets/images/downloadfile.gif";
import { useSelector } from "react-redux/es/exports";
// import { useDispatch } from "react-redux/es/exports";
// import { uploadDataProcessing } from "../../redux/actions/downloadFile";

function ProcessingTab() {
  // let dispatch = useDispatch();

  const getFileId = useSelector((state) => state.fileUpload.getFileId);
  // console.log("getFileId", getFileId);

  const fileDownload =
    "https://arvs-api.theretailinsights.co/api/v1/reports/sales/";

  const getFileDownload = fileDownload + getFileId;

  // const downloadFileInLocal = () => {
  //   dispatch(uploadDataProcessing(getFileId));
  // };

  // console.log("processingState");
  const [showElement, setShowElement] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 6000);
  }, []);
  return (
    <>
      <ProgressBars steps={100} />
      <div className="progressName">
        <div>Document Upload</div>
        <div>Column Mapping</div>
        <div>Data Processing</div>
      </div>
      {showElement ? (
        <div className="dataprocess">
          <div>
            <img
              className="upload-img"
              processing
              alt="processing"
              src={save}
              width="30%"
            />
          </div>
          <div>
            Column Mapping Saved Successfully.!
            <br></br>
            <br></br>
            You will receive an email once the data process is complete.
          </div>
        </div>
      ) : (
        <div className="Download-file">
          <div>
            <img
              className="upload-img"
              alt="download"
              src={download}
              width="30%"
            />
          </div>
          <div className="download">
            <a href={getFileDownload} download>
              <p
                className="downloadFile"
                // onClick={downloadFileInLocal}
              >
                Download
              </p>
            </a>
            <p>the file to verify the data corrections.</p>
          </div>
        </div>
      )}{" "}
    </>
  );
}

export default ProcessingTab;
