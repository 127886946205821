/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
//import React from 'react';
import "./LoginStyle.css";
import arvind from "../../assets/images/arvind.png";
import company from "../../assets/images/briefcase.png";
import pwd from "../../assets/images/lock.png";
import PropTypes from "prop-types";
import Toast from "../../components/Popup/Popup";

// import { Navigate } from "react-router-dom";
// import axios from "axios";
// import { Route } from "react-router-dom";
// import Dashboard from "../DashboardContent/Dashboard";
// import { render } from "@testing-library/react";
// import { Redirect } from "react-router-dom";
// import { BrowserHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createContext } from "react";

export const AuthContext = createContext();

// const rmCheck = document.getElementById("rememberMe"),
//     emailInput = document.getElementById("login");

// if (localStorage.checkbox && localStorage.checkbox !== "") {
//   rmCheck.setAttribute("checked", "checked");
//   emailInput.value = localStorage.username;
// } else {

//   rmCheck.removeAttribute("checked");
//   emailInput.value = "";
// }

// function lsRememberMe() {
//   if (rmCheck.checked && emailInput.value !== "") {
//     localStorage.username = emailInput.value;
//     localStorage.checkbox = rmCheck.value;
//   } else {
//     localStorage.username = "";
//     localStorage.checkbox = "";
//   }
// }
// export const ProtectedRoute = ({ children }) => {
//   const { user } = useAuth();
//   if (!user) {
//     // user is not authenticated
//     return <Navigate to="/" />;
//   }
//   return children;
// };

function Login() {
  let navigate = useNavigate();
  const [_username, setUserName] = useState();
  localStorage.setItem("userName", JSON.stringify(_username));
  const [_password, setPassword] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser({
      _username,
      _password,
    });
    console.log(token);
  };

  async function loginUser(credentials) {
    const axios = require("axios");
    const config = {
      method: "post",
      url: "https://arvs-api.theretailinsights.co/api/v1/login",
      headers: { "Content-Type": "application/json" },
      data: credentials,
    };
    return axios(config)
      .then(function (response) {
        const result = JSON.parse(JSON.stringify(response.data));
        if (result.access) localStorage.setItem("arvindLogin", result.access);
        Toast("Login success", "success");
        navigate("/dashboard", { replace: true });
      })

      .catch((err) => {
        console.log(err);
        Toast("Enter valid email ID or password", "error");
      });
  }

  return (
    <div className="login-wrapper">
      <div>
        <div>
          <div className="side-bar"></div>
        </div>
        <div>
          <div className="main-content">
            <div className="login">
              <div className="card-holder">
                <div className="card-body">
                  <div className="logo-login">
                    <img src={arvind} width="200px" height="auto" alt="img" />
                  </div>
                  <p className="welcome">Welcome Back</p>
                  <p className="account">Login to your Account</p>

                  <form id="loginform" onSubmit={handleSubmit}>
                    <div id="login">
                      <img src={company} width="5%" alt="img" />
                      <input
                        className="text-input_login"
                        type="text"
                        placeholder="Company ID"
                        onChange={(e) => setUserName(e.target.value)}
                      ></input>
                    </div>

                    <div className="pwd">
                      <img src={pwd} width="5%" alt="img" />
                      <input
                        className="text-input_login"
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      ></input>
                    </div>

                    <div className="remember">
                      <input
                        type="checkbox"
                        value="lsRememberMe"
                        id="rememberMe"
                      ></input>
                      <label for="rememberMe">Remember me</label>
                    </div>

                    <div className="sign-in">
                      <button className="btn-signin" type="submit">
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
