import React from "react";
import "./History.css";
import { useSelector } from "react-redux";

const HistoryTable = () => {
  const getApiResponceForHistory = useSelector(
    (state) => state.historyGetValue.getHistoryResponce.data
  );

  console.log("getApiResponceForHistory", getApiResponceForHistory);

  return (
    <>
      <div className="table-size">
        <table className="history-table">
          <tr>
            <th>ean_number</th>
            <th>item_code</th>
            <th>bill_no</th>
            <th>dealer_door_name</th>
            <th>voyager_code</th>
            <th>brand_code</th>
            <th>category</th>
            <th>season</th>
            <th>sales_mrp</th>
            <th>sales_qty</th>
            <th>sales_gsv</th>
            <th>discount_amt</th>
            <th>discount_prct</th>
            <th>size</th>
            <th>bill_date</th>
            <th>agency_name</th>
            <th>region</th>
            <th>offer_type</th>
            <th>channel_code</th>
          </tr>
          {getApiResponceForHistory.map((item) => (
            <tr>
              <td>{item.ean_number}</td>
              <td>{item.item_code}</td>
              <td>{item.bill_no}</td>
              <td>{item.dealer_door_name}</td>
              <td>{item.voyager_code}</td>
              <td>{item.brand_code}</td>
              <td>{item.category}</td>
              <td>{item.season}</td>
              <td>{item.sales_mrp}</td>
              <td>{item.sales_qty}</td>
              <td>{item.sales_gsv}</td>
              <td>{item.discount_amt}</td>
              <td>{item.discount_prct}</td>
              <td>{item.size}</td>
              <td>{item.bill_date}</td>
              <td>{item.agency_name}</td>
              <td>{item.region}</td>
              <td>{item.offer_type}</td>
              <td>{item.channel_code}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};
export default HistoryTable;
