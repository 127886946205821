import React from "react";
import "./buttons.css";
function buttons(props) {
  return (
    <>
      <button
        className={props.disabled ? "data-disable-btn" : "data-button"}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.data}{" "}
      </button>
    </>
  );
}

export default buttons;
