import React from "react";
import "react-step-progress-bar/styles.css";
import "./statusbar.css";
import { ProgressBar, Step } from "react-step-progress-bar";

class ProgressBars extends React.Component {
  render() {
    return (
      <ProgressBar percent={this.props.steps}>
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
            >
              {/* <div class="checkmark"></div> */}

              {index + 1}
            </div>
          )}
        </Step>

        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
            >
              {index + 1}
            </div>
          )}
        </Step>

        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
            >
              {index + 1}
            </div>
          )}
        </Step>
      </ProgressBar>
    );
  }
}

export default ProgressBars;
